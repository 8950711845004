import React from 'react';

import { SVGProps } from '../index';

const Communication: React.FC<SVGProps> = ({
    width = 50,
    height = 50,
    className,
}: SVGProps) => {
    return (
        <svg
            className={className}
            height={height}
            width={width}
            viewBox="0 -5 95 95"
        >
            <g>
                <path d="M61.134,53.604V28.926h-7.961c-10.662,0-19.305,8.644-19.305,19.306c0,3.729,1.075,7.197,2.905,10.15h19.584   C58.994,58.382,61.134,56.242,61.134,53.604z" />
                <path d="M69.094,28.926h-5.96v24.679c0,3.737-3.04,6.777-6.776,6.777H38.198c3.536,4.359,8.923,7.154,14.975,7.154h11.28   l7.691,7.693v-7.958c9.208-1.465,16.254-9.42,16.254-19.04C88.398,37.569,79.756,28.926,69.094,28.926z" />
                <path d="M53.173,26.926h7.961v-2.378c0-2.639-2.14-4.777-4.776-4.777H11.379c-2.638,0-4.777,2.139-4.777,4.777v29.057   c0,2.638,2.14,4.777,4.777,4.777H13.9v6.764l6.764-6.764H34.47c-1.7-3.125-2.602-6.606-2.602-10.15   C31.868,36.483,41.426,26.926,53.173,26.926z" />
            </g>
        </svg>
    );
};

export default Communication;
