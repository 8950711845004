import React from 'react';

import { SVGProps } from '../index';

const Leadership: React.FC<SVGProps> = ({
    width = 50,
    height = 50,
    className,
}: SVGProps) => {
    // This view box hurts me
    return (
        <svg
            className={className}
            height={height}
            width={width}
            viewBox="0 0 545.52 317.44"
        >
            <g>
                <path d="M272.76 0c46.4,0 84.01,40.74 84.01,91.01 0,50.26 -37.61,91.01 -84.01,91.01 -46.4,0 -84.01,-40.75 -84.01,-91.01 0,-50.27 37.61,-91.01 84.01,-91.01z"></path>
                <path d="M272.76 208.5c65.89,0 120.84,46.77 133.5,108.94l-267 0c12.66,-62.17 67.61,-108.94 133.5,-108.94z"></path>
                <path d="M441.15 26.41c35.59,0 64.45,31.24 64.45,69.81 0,38.56 -28.86,69.81 -64.45,69.81 -35.59,0 -64.45,-31.25 -64.45,-69.81 0,-38.57 28.86,-69.81 64.45,-69.81z"></path>
                <path d="M441.15 186.35c55.94,0 101.61,43.94 104.37,99.21l-125.93 0c-11.03,-27.38 -29.49,-50.88 -52.81,-68.13 18.95,-19.19 45.27,-31.08 74.37,-31.08z"></path>
                <path d="M104.37 26.41c35.59,0 64.45,31.24 64.45,69.81 0,38.56 -28.86,69.81 -64.45,69.81 -35.59,0 -64.45,-31.25 -64.45,-69.81 0,-38.57 28.86,-69.81 64.45,-69.81z"></path>
                <path d="M104.37 186.35c29.1,0 55.42,11.89 74.37,31.08 -23.32,17.25 -41.78,40.76 -52.81,68.13l-125.93 0c2.76,-55.27 48.43,-99.21 104.37,-99.21z"></path>
            </g>
        </svg>
    );
};

export default Leadership;
