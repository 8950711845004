import React from 'react';

import { SVGProps } from '../index';

const Mentorship: React.FC<SVGProps> = ({
    width = 50,
    height = 50,
    className,
}: SVGProps) => {
    return (
        <svg
            className={className}
            height={height}
            width={width}
            viewBox="-2 -8 100 100"
        >
            <path d="M85.9,19.2c-4.7-4.7-11-7.3-17.6-7.3s-12.9,2.6-17.6,7.3c-0.1,0.1-17.1,17.1-19.9,19.9c-0.4,0.4-0.8,1.2,0.5,2.6    c0.7,0.7,1.7,1.3,2.8,1.6l0.6,0.2l14.9-14.9c0.5-0.5,1.6-0.5,2.1,0c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.2,0.8-0.4,1.1L24.5,57.8    c-1.6,1.6-1.6,4.1,0,5.7c0.7,0.7,1.8,1.1,2.8,1.1s2.1-0.4,2.8-1.1l16-16c0.5-0.5,1.6-0.5,2.1,0c0.3,0.3,0.4,0.7,0.4,1.1    c0,0.4-0.2,0.8-0.4,1.1l-16,16c-1.6,1.6-1.6,4.1,0,5.7c0.7,0.7,1.8,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1l16-16    c0.6-0.6,1.6-0.6,2.1,0c0.3,0.3,0.4,0.7,0.4,1.1s-0.2,0.8-0.4,1.1l-16,16c-0.8,0.8-1.2,1.8-1.2,2.8c0,1.1,0.4,2.1,1.2,2.8    c0.7,0.7,1.8,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1l16-16c0.6-0.6,1.6-0.6,2.1,0c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.2,0.8-0.4,1.1    l-16,16c-0.8,0.8-1.2,1.8-1.2,2.8c0,1.1,0.4,2.1,1.2,2.8c0.7,0.7,1.8,1.1,2.8,1.1c1.1,0,2.1-0.4,2.8-1.1l32.3-32.4    c4.7-4.7,7.3-11,7.3-17.6C93.2,30.1,90.6,23.9,85.9,19.2z M31.1,45.2c-0.8-0.4-1.4-0.9-2-1.4c-2.2-2.2-2.4-4.9-0.5-6.8l19.8-19.8    l-1-0.7c-4.2-3-9.2-4.6-14.3-4.6c-6.7,0-12.9,2.6-17.6,7.3c-4.7,4.7-7.3,11-7.3,17.6c0,6.7,2.6,12.9,7.3,17.6l4.8,4.8l0.6-1.4    c0.4-0.8,0.9-1.6,1.5-2.2l9.8-9.8L31.1,45.2z"></path>
        </svg>
    );
};

export default Mentorship;
